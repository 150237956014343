@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-ellipsis::after {
    content: '...';
    animation: ellipsis 3s infinite;
}

@keyframes ellipsis {
    0% {
        content: '';
    }
    33% {
        content: '.';
    }
    66% {
        content: '..';
    }
    100% {
        content: '...';
    }
}

.slick-arrow {
    color: white;
}
/* Basic editor styles */
.tiptap > * + * {
    margin-top: 0.75em;
}
.tiptap ul,
.tiptap ol {
    padding: 0 1rem;
}
.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
    line-height: 1.1;
}
.tiptap code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
}
.tiptap pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
}
.tiptap pre code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
}
.tiptap mark {
    background-color: #faf594;
}

#leva__root {
    display: none;
}
.tiptap img {
    height: auto;
    max-width: 100%;
}
.tiptap hr {
    margin: 1rem 0;
}
.tiptap blockquote {
    border-left: 2px solid rgba(13, 13, 13, 0.1);
    padding-left: 1rem;
}
.tiptap hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}
.tiptap ul[data-type='taskList'] {
    list-style: none;
    padding: 0;
}
.tiptap ul[data-type='taskList'] li {
    align-items: center;
    display: flex;
}
.tiptap ul[data-type='taskList'] li > label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
}
.tiptap ul[data-type='taskList'] li > div {
    flex: 1 1 auto;
}
.editor {
    background-color: #fff;
    border: 3px solid #0d0d0d;
    border-radius: 0.75rem;
    color: #0d0d0d;
    display: flex;
    flex-direction: column;
    max-height: 26rem;
    /* Some information about the status */
}
.editor__header {
    align-items: center;
    background: #0d0d0d;
    border-bottom: 3px solid #0d0d0d;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
}
.editor__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.25rem 1rem;
    -webkit-overflow-scrolling: touch;
}
.editor__footer {
    align-items: center;
    border-top: 3px solid #0d0d0d;
    color: #0d0d0d;
    display: flex;
    flex: 0 0 auto;
    font-size: 12px;
    flex-wrap: wrap;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    white-space: nowrap;
}
.editor__status {
    align-items: center;
    border-radius: 5px;
    display: flex;
}
.editor__status::before {
    background: rgba(13, 13, 13, 0.5);
    border-radius: 50%;
    content: ' ';
    display: inline-block;
    flex: 0 0 auto;
    height: 0.5rem;
    margin-right: 0.5rem;
    width: 0.5rem;
}
.editor__status--connecting::before {
    background: #616161;
}
.editor__status--connected::before {
    background: #b9f18d;
}
.editor__name button {
    background: none;
    border: none;
    border-radius: 0.4rem;
    color: #0d0d0d;
    font: inherit;
    font-size: 12px;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
}
.editor__name button:hover {
    background-color: #0d0d0d;
    color: #fff;
}
/* Give a remote user a caret */
.collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
}
/* Render the username above the caret */
.collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */

.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.loader {
    width: 30px;
    aspect-ratio: 1;
    --_c: no-repeat radial-gradient(farthest-side, #9333ea 92%, #0000);
    background:
        var(--_c) top,
        var(--_c) left,
        var(--_c) right,
        var(--_c) bottom;
    background-size: 8px 8px;
    animation: l7 1s infinite;
}
@keyframes l7 {
    to {
        transform: rotate(0.5turn);
    }
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */

.terminal-input {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    box-shadow: none; /* Remove focus border highlighting */
}
.terminal-input:focus {
    outline: none; /* Remove focus outline */
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;

        /* Custom properties */
        --navigation-height: 3.5rem;
        --color-one: #2563eb;
        --color-two: #fe8bbb;
        --color-three: #9e7aff;

        /* 
    --color-one: #37ecba;
    --color-two: #72afd3;
    --color-three: #ff2e63;
     */
    }

    .dark {
        --background: 0 0% 0%;
        --foreground: 0 0% 98%;

        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

.no-scroll {
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: Rubik;
}

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.blinking-cursor {
    position: relative;
}

.blinking-cursor::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: white;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.text-2xs {
    font-size: 0.6rem;
    line-height: 0.9rem;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.main {
    background: linear-gradient(to bottom, #000000, #0a192f);
}

.hero-section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered-text {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: inherit;
}

.team {
    position: relative;
}

.features {
    position: relative;
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
/*
.hero-section h2 {
    font-size: 3.5rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 1rem;
}

.hero-section h3 {
    font-size: 2rem;
    color: #c4c4c4;
    margin-bottom: 2rem;
}
.hero-section .btn {
    display: inline-block;
    background-color: #ffffff;
    color: #0a192f;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border-radius: 0.25rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(10, 25, 47, 0.15);
}

.hero-section .btn:hover {
    background-color: #2980b9;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(41, 128, 185, 0.3);
}*/
.services-section {
    position: relative;
}

.services-section::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 2px;
    background-color: transparent; /* Update the color value here */
}

.section-title {
    position: relative;
    z-index: 1;
    color: #f2f2f2; /* Update the font color here */
    margin-bottom: 20px; /* Add a margin to create space */
}
.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes streak {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.streak-effect {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    animation: streak 1s linear forwards;
}

.text-primary {
    color: #00e3a0;
}

.text-primary {
    color: #00e3a0;
}

.step {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    transition: padding-left 0.3s ease;
}

.step.active {
    padding-left: 35px;
}

.step.active::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 100%;
    width: 4px;
    background: linear-gradient(to bottom, transparent 0%, #00e3a0 100%);
}
.icon {
    transition: all 0.3s ease-in-out;
}

.icon.growing {
    transform: scale(1.2); /* Adjust the scale factor as needed */
}

.icon-container {
    border-radius: 50%;
    padding: 5px;
    transition: box-shadow 0.3s ease-in-out;
}

.icon-container.selected {
    box-shadow: 0px 0px 5px #00e3a0;
}

.text-gray-400 {
    color: #ccc;
}

@keyframes comet-grow {
    0% {
        transform: scale(1);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Comet styles */
.comet {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5)
    );
    animation: comet-grow 1.5s infinite;
    opacity: 0;
}

.comet.active {
    animation-play-state: running;
    opacity: 1;
}
@keyframes selector-grow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* Selector styles */
.selected .icon {
    animation: selector-grow 1s infinite;
}

/* For the feature items */
.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature-item h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.feature-item p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.feature-item a {
    font-size: 16px;
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
}
.feature-image {
    height: auto;
    width: 100%;
}
.feature-item a:hover {
    text-decoration: underline;
}

/* For the "View All Features" button */
.view-all-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 0;
    margin-bottom: 1rem;
}
